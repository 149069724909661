<template>
  <el-dialog :title="$t('maintWorkOrder.maintItem')" :visible.sync="dialogVisible" width="1500px" top="2vh"
    append-to-body>
    <el-table v-loading="loading" :data="maintItemList" stripe border>
      <el-table-column type="index" :label="$t('common.index')" width="60px" align="center"></el-table-column>
      <table-column prop="itemName" :label="$t('maintWorkOrder.maintenanceProjectName')"></table-column>
      <table-column prop="maintContent" :label="$t('maintWorkOrder.maintContent')"></table-column>
      <table-column prop="maintRequire" :label="$t('maintWorkOrder.maintRequire')"></table-column>
      <table-column prop="maintArea" :label="$t('maintWorkOrder.maintArea')" width="100px">
        <template #default="scope">
          <span v-if="scope.row.maintArea === '机房'">
            {{ $t("maintWorkOrder.maintenanceArea.machineRoom") }}
          </span>
          <span v-else-if="scope.row.maintArea === '轿厢'">
            {{ $t("maintWorkOrder.maintenanceArea.bridge") }}
          </span>
          <span v-else-if="scope.row.maintArea === '层站'">
            {{ $t("maintWorkOrder.maintenanceArea.layer") }}
          </span>
          <span v-else-if="scope.row.maintArea === '井道及底坑'">
            {{ $t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") }}
          </span>
          <span v-else-if="scope.row.maintArea === '扶梯'">
            {{ $t("maintWorkOrder.maintenanceArea.escalator") }}
          </span>
          <span v-else-if="scope.row.maintArea === '其他'">
            {{ $t("maintWorkOrder.maintenanceArea.other") }}
          </span>
        </template>
      </table-column>
      <table-column prop="remark" :label="$t('common.remark')"></table-column>
      <table-column prop="isDefault" :label="$t('common.isDefault')" align="center" width="130px">
        <template #default="scope">
          <el-tag v-if="scope.row.isDefault === 1" type="success">
            {{ $t("common.yes") }}
          </el-tag>
          <el-tag v-else-if="scope.row.isDefault === 0" type="info">
            {{ $t("common.no") }}
          </el-tag>
        </template>
      </table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      dialogVisible: false,
      maintItemList: [],
    };
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.getData(id);
    },
    getData(id) {
      this.$api.getById("maintType", id).then(res => {
        this.getMaintItemList(res.data.maintItemIdList);
        this.loading = false;
      }).catch((error) => {
        if (error.response) {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    getMaintItemList(idList) {
      let param = {
        idList: idList.toString(),
      };
      this.$api.getData("maintItem/type", param).then(res => {
        this.maintItemList = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("maintWorkOrder.tip.getMaintTypeItemError") + "," + error.response.data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>